body {
  background-color: rgb(255, 255, 255);
  /* background-color: rgb(80, 127, 98); */
}

.App {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fbdf61;
}

/* Navigation */

.site-content {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

header {
  list-style-type: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  top: 0;
  width: 100%;
  user-select: none; /* Standard */
  position: sticky;
  z-index: 99;
}

.nav-cont {
  display: flex;
  justify-content: flex-start;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  top: 0;
  /* padding-left: 15%;
  padding-right: 15%; */
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  /* border-bottom: 1px solid black; */
}

.nav {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin: 0;
}

.mob-cont {
  display: none;
  overflow: hidden;
  align-items: center;
  width: 100%;
}

.mobilenav {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column wrap;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin: 0;
  transition: margin 0.3s;
  font-size: 24px;
}

.artist {
  float: left;
  display: block;
  color: rgb(0, 0, 0);
  font-size: 28px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.nickname {
  font-weight: 300;
}

.navlink {
  float: left;
  display: flex;
  flex-flow: row wrap;
  color: gray;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.navlink:hover {
  color: rgb(0, 0, 0);
}

.hamburger-click-area {
  padding: 24px;
  display: none;
  opacity: 0.5;
  background-color: rgb(255, 255, 255);
  align-items: center;
}

.hamburger {
  width: 24px;
  height: 14px;
}

.hamburger i {
  /* transition: width 1s, margin-left 1s, transform 0.5s; */
  display: block;
  height: 2px;
  margin-bottom: 5px;
  background-color: black;
}

.hamburger-click-area:hover {
  opacity: 1;
}

/* Projects */
main {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.project-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex: 1;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
  border: 0.5em solid transparent;
}

.project-card {
  position: relative;
  overflow: hidden;
  width: 33.3333%;
  border: 0.5em solid transparent;
  box-sizing: border-box;
}

.project-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.project {
  /* height: 10%; */
  /* left: 50%;
  top: 50%; */
  transform: translate(-50%, -50%);
  /* border: 1em solid green; */
  box-sizing: border-box;
}

.project img {
  width: 100%;
  object-fit: cover;
  /* transform: scale(2); */
  position: absolute;
  /* color: rgb(219, 126, 20); */
  transition: transform 0.5s;
}

.project:hover {
  /* opacity: 0.7; */
  img {
    transform: scale(1.05);
  }
}

/* Project Display */

.project-display {
  width: 100%;
  display: flex;
  justify-content: center;
  /* height: 100vh; */
}

.project-full {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}

.project-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-next {
  width: 100%;
  height: 40vh;
  /* align-self: flex-start; */
  display: none;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  position: sticky;
  /* position: relative; */
  /* top: calc(50% - (20vh) - (80px)); */
  top: 100px;
  opacity: 1;
  /* transition: opacity 0.1s; */
}

@media (min-width: 900px) {
  .prev-next {
    display: flex;
  }
}

.prev-next:hover {
  /* opacity: 0.5; */
  cursor: pointer;
  .arrow-left,
  .arrow-right {
    transform: scale(1.3);
  }
}

.hide-arrows {
  display: none;
}

.arrow-left {
  align-self: flex-start;
  margin-left: 40px;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-bottom: 80px solid transparent;
  border-right: 20px solid rgb(0, 0, 0);
  transition: transform 0.2s;
}

.arrow-right {
  align-self: flex-end;
  margin-right: 40px;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-bottom: 80px solid transparent;
  border-left: 20px solid rgb(0, 0, 0);
  transition: transform 0.2s;
}

.project-nav img {
  width: 100%;
  max-height: 75vh;
  object-fit: contain;
  cursor: zoom-in;
}

@media (min-width: 900px) {
  .project-nav img {
    width: 75%;
    max-height: 75vh;
  }
}

.full-image {
  width: 100%;
  max-height: 100vh;
}

.title {
  font-weight: bold;
}

.description {
}

/* Project Page Transitions */

/* Exhibitions Display */

.ex-container {
  padding-left: 10%;
  padding-right: 10%;
}

.exhibitions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.residencies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.exhibition {
  text-align: justify; /* For Edge */
  -moz-text-align-last: left; /* For Firefox prior 58.0 */
  text-align-last: left;
}

/* About Page */
.about-page {
  font-size: 21px;
  /* text-indent: 64px; */
  text-align: left;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-flow: column wrap;
}

.profile-pic {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 480px;
  max-height: 360px;
  /* float: left; */
  align-self: center;
  margin-bottom: 25px;
  margin-top: 25px;
  border: 1px solid black;
}

.text p {
  margin: 0;
  line-height: 1.5;
}
/* Media Adjust */
/*768px?*/

@media (max-width: 900px) {
  .project-card {
    width: 50%;
    border: 0.5em solid transparent;
  }

  .nav-cont {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .project-container {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .hamburger-click-area {
    display: flex;
  }

  .nav {
    display: none;
  }

  .mob-cont {
    display: flex;
  }
}

@media (max-width: 700px) {
  .project-card {
    width: 100%;
  }
}

/* Contact Page */

.contact-page {
  display: flex;
  justify-content: center;
  text-align: left;
  width: 80%;
  margin-top: 64px;
  margin-left: 10%;
}

.contact-form {
  width: 450px;
  display: flex;
  flex-flow: column wrap;
}

.contact-form h1 {
  color: rgb(0, 0, 0);
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
}

.contact-form input {
  outline: none;
  height: 40px;
  font-size: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.contact-form textarea {
  height: 200px;
  outline: none;
  resize: vertical;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.contact-form label {
  font-size: 18px;
}

.shake {
  animation: shake 0.2s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 0px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  40% {
    transform: translate(1px, 0px);
  }
  60% {
    transform: translate(-3px, 0px);
  }
  80% {
    transform: translate(-1px, 0px);
  }
  100% {
    transform: translate(1px, 0px);
  }
}

.error {
  /* background-color: rgb(221, 221, 221); */
  display: none;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  list-style: none;
  background: rgb(255, 216, 222);
  border: 1px solid rgb(158, 54, 71);
  padding: 1px;
}

.error li {
  padding-left: 1.2em;
  font-size: 14px;
  color: rgb(0, 0, 0);
}

.error li:before {
  content: "❌";
  font-size: 9px;
  margin-top: 1px;
  position: absolute;
  left: 0;
}

.submit-btn {
}

.shop-page {
  display: flex;
  margin-left: 40%;
}

.shop-page h1 {
  color: rgb(0, 0, 0);
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
  align-self: center;
}
